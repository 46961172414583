import { useEffect, useState } from "react"
import styled from "styled-components"

import {
  CardStatusRow,
  MonitoringCard,
  StatusLeft,
} from "src/components/Homes/HomeDetails/Overview/MonitoringCard"
import { SecurityAlarmButton } from "src/components/Homes/HomeDetails/SecurityAlarm/SecurityAlarmButton"
import { TDevice } from "src/data/devices/types/deviceTypes"
import {
  getSecurityAlarmLabel,
  getUIAlarmState,
} from "src/data/homeActions/logic/securityAlarm"
import { IAlarm } from "src/data/homes/types/alarmTypes"
import { Violations } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import AlertingIcon from "src/ui/icons/important-outlined.svg"
import SecurityAlarmIcon from "src/ui/icons/security-alarm.svg"
import SilentAlarmIcon from "src/ui/icons/silent-alarm.svg"
import { spacing } from "src/ui/spacing"
import { formatDateAsTime } from "src/utils/l10n"

export function SecurityAlarmCard({
  homeId,
  showAlarmDelayHint,
  canToggle,
  violations,
  homeAlarm,
  devices,
}: {
  homeId: string
  canToggle: boolean
  showAlarmDelayHint?: boolean
  violations: Violations[] | undefined
  homeAlarm: IAlarm
  devices: TDevice[]
}) {
  const { t } = useTranslate()
  const clockType = useGetUser().clock_type

  const deadline = !!homeAlarm.grace_period_expires_at
    ? new Date(homeAlarm.grace_period_expires_at).getTime()
    : null
  const [now, setNow] = useState(Date.now())
  const uiAlarmState =
    homeAlarm.alarm_status && getUIAlarmState(homeAlarm.alarm_status, deadline)

  const motionEvent = homeAlarm.events?.find((event) => !!event)

  const deviceName = devices.find(
    (device) => device.device_id === motionEvent?.device_id
  )?.description

  const showMotionAlert = !!motionEvent && !!deviceName

  const motionAlertTime = formatDateAsTime({
    date: motionEvent?.occurred_at || "",
    clockType,
    excludeSeconds: true,
  })

  useEffect(() => {
    if (uiAlarmState !== "on_grace_period") {
      return
    }
    setNow(Date.now())
    const interval = setInterval(() => {
      setNow(Date.now())
    }, 1000)
    return () => clearInterval(interval)
  }, [uiAlarmState])

  const ActionButton = (!!homeAlarm.alarm_status && (
    <SecurityAlarmButton
      key="right"
      homeId={homeId}
      showAlarmArmDelayHint={!!showAlarmDelayHint}
      alarmStatus={homeAlarm.alarm_status}
      gracePeriodExpiresAt={homeAlarm.grace_period_expires_at}
      gracePeriodSecs={homeAlarm.grace_period_secs}
      silentAlarm={homeAlarm.silent_alarm}
      alarmMode={homeAlarm.alarm_mode}
      toggleAllowed={canToggle}
      violations={violations}
    />
  )) || <></>

  function LeftCol() {
    if (!uiAlarmState) {
      return null
    }
    const statusOn = ["on", "on_grace_period"].includes(uiAlarmState)

    return (
      <StatusLeft
        icon={<SecurityAlarmIcon width={32} height={32} />}
        title={t(langKeys.home_security_alarm_title)}
        statusContents={getSecurityAlarmLabel({
          t,
          now,
          deadline,
          alarmStatus: uiAlarmState,
        })}
        isOn={statusOn}
        secondaryFeatureStatus={
          homeAlarm.silent_alarm && <SilentAlarmIcon width={27} height={27} />
        }
      />
    )
  }

  return (
    <MonitoringCard
      titleSlots={[<LeftCol key="left" />, ActionButton]}
      cardFooter={{
        showTopBorder: showMotionAlert,
        component: showMotionAlert && (
          <CardStatusRow
            icon={<AlertingIcon width={24} />}
            subtitle={t(langKeys.alarm_grace_period_expired_title)}
            body={
              <StatusBox style={{ display: "inline-flex" }}>
                <div>{motionAlertTime}</div>
                <div>&#x2022;</div>
                <div>{deviceName}</div>
              </StatusBox>
            }
            color="emergency"
          />
        ),
      }}
    ></MonitoringCard>
  )
}

const StatusBox = styled.div`
  display: flex;
  gap: ${spacing.XS2};
`
