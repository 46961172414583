import styled from "styled-components"

import { HomeMemberTable } from "src/components/Homes/HomeDetails/HomeMembers/HomeMemberTable"
import { useFetchHomeMembers } from "src/data/organizations/queries/homeQueries"
import { TOrganizationRole } from "src/data/organizations/types/organizationMemberTypes"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DropdownListButton } from "src/ui/Button/DropdownListButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeMembers({
  orgId,
  homeId,
  homeRole,
}: {
  orgId: string
  homeId: string
  homeRole: TOrganizationRole
}) {
  const { navigate } = useRouter()

  const { t, langKeys } = useTranslate()
  const fetchHomeMembers = useFetchHomeMembers({ orgId, homeId })
  const members = fetchHomeMembers.data?.members ?? []

  const adminAccess = getAccessLogic({ role: homeRole }).hasAdminAccess

  return (
    <HomeMembersBox>
      <Title>
        <MText variant="heading2">{t(langKeys.home_access_title)}</MText>
        <DropdownListButton
          displayValue={t(langKeys.organizations_add_member)}
          hidden={!adminAccess}
          options={[
            {
              name: "Add new member",
              value: "new-member",
              onClick: () =>
                navigate({
                  pathname: Routes.OrgHomeNewMemberAdd.location({
                    orgId,
                    homeId,
                  }).pathname,
                }),
            },
            {
              name: "Add existing member",
              value: "existing-member",
              onClick: () =>
                navigate({
                  pathname: Routes.OrgHomeExistingMemberAdd.location({
                    orgId,
                    homeId,
                  }).pathname,
                }),
            },
          ]}
        />
      </Title>
      <TeamBox
        $visible={!!members?.length || true}
        $isFetching={fetchHomeMembers.isFetching}
      >
        <HomeMemberTable
          orgId={orgId}
          homeId={homeId}
          members={members}
          loading={fetchHomeMembers.isLoading}
          adminAccess={adminAccess}
        />
      </TeamBox>
    </HomeMembersBox>
  )
}

const TeamBox = styled.div<{ $visible?: boolean; $isFetching?: boolean }>`
  display: ${(props) => (props.$visible ? "grid" : "none")};
  opacity: ${(props) => (props.$isFetching ? 0.5 : 1)};
`

const HomeMembersBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.L};
  margin-bottom: ${spacing.L};
`
