import styled from "styled-components"

import { FireCOAlarmButton } from "src/components/Homes/HomeDetails/FireCOAlarm/FireCOAlarmButton"
import {
  MonitoringCard,
  StatusLeft,
} from "src/components/Homes/HomeDetails/Overview/MonitoringCard"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { colorsLegacy } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import FireCOIcon from "src/ui/icons/fire-alarm.svg"
import ImportantIcon from "src/ui/icons/important-filled-default.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDateAsTime } from "src/utils/l10n"

export function FireCOAlarmCard({ home }: { home: THome }) {
  const { t } = useTranslate()

  const fireAlarmAlerting = home.fire_alarm?.status === "alerting"
  const coAlarmAlerting = home.carbon_monoxide_alarm?.status === "alerting"
  const alerting = fireAlarmAlerting || coAlarmAlerting

  function ActionButton() {
    return <FireCOAlarmButton homeState={home} configureAllowed={true} />
  }

  function LeftCol() {
    // N.B: the Fire & CO Alarm cannot be turned off, so we're hard coding the
    // status to 'on', at least for now
    return (
      <StatusLeft
        icon={<FireCOIcon width={32} height={32} />}
        title={t(langKeys.fire_and_co_alarm_title)}
        statusContents={t(langKeys.on)}
        isOn={true}
      />
    )
  }

  return (
    <MonitoringCard
      titleSlots={[<LeftCol key="left" />, <ActionButton key="right" />]}
    >
      {alerting && (
        <AlertInfo
          fireAlarmAlerting={fireAlarmAlerting}
          coAlarmAlerting={coAlarmAlerting}
          home={home}
        />
      )}
    </MonitoringCard>
  )
}

function AlertInfo({
  fireAlarmAlerting,
  coAlarmAlerting,
  home,
}: {
  fireAlarmAlerting: boolean
  coAlarmAlerting: boolean
  home: THome
}) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const { orgId } = useOrganization()
  const fetchAlertingDevices = useFetchDevices({
    orgId,
    filter: { home_ids: [home.home_id] },
    options: {
      select: (data) => data.devices.filter(isFireCoAlerting),
    },
  })

  const alarmData =
    coAlarmAlerting && !fireAlarmAlerting
      ? {
          copy: t(langKeys.co_alarm_triggered_title),
          updatedAt: home.carbon_monoxide_alarm?.updated_at,
        }
      : {
          copy: t(langKeys.fire_alarm_triggered_title),
          updatedAt: home.fire_alarm?.updated_at,
        }

  const alarmUpdated = alarmData.updatedAt
    ? formatDateAsTime({
        date: alarmData.updatedAt,
        excludeSeconds: true,
        clockType: user.clock_type,
        timezone: home.timezone,
      })
    : null

  const alertingDevices: TDevice[] = fetchAlertingDevices.data ?? []
  const alertingDeviceNames = alertingDevices.map((d) => d.description)

  return (
    <AlertInfoBox>
      <Divider />
      <AlarmBox>
        <ImportantIcon width={24} fill={colorsLegacy.semanticEmergency} />
        <MText variant="subtitle">{alarmData.copy}</MText>
        {alertingDeviceNames.length ? (
          <MText variant="nano" color="secondary" hidden={!alarmUpdated}>
            {alarmUpdated} - {alertingDeviceNames.join(", ")}
          </MText>
        ) : null}
      </AlarmBox>
    </AlertInfoBox>
  )
}

const AlarmBox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.XS2};
  & > :not(svg) {
    grid-column: 2;
  }
`
const AlertInfoBox = styled.div`
  display: grid;
  gap: ${spacing.L};
  margin-top: ${spacing.L};
`

function isFireCoAlerting(d: TDevice) {
  return (
    d.fire_alarm?.status === "alerting" ||
    d.carbon_monoxide_alarm?.status === "alerting"
  )
}
