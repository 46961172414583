import styled from "styled-components"

import { FeatureBlockerText } from "src/components/FeatureBlockers/FeatureBlockerText"
import { ShortcodeTable } from "src/components/Homes/HomeDetails/Shortcodes/ShortCodeTable"
import { HREF_MINUT_HELP_SHORTCODES } from "src/constants/hrefs"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import {
  DEFAULT_CHECKIN_MESSAGE,
  IGuestCommunication,
} from "src/data/homes/types/homeTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function Shortcodes({
  homeId,
  guestCommunication,
}: {
  guestCommunication: IGuestCommunication | undefined
  homeId: string
}) {
  const { t } = useTranslate()

  const guestConnectAvailability = useFeatureAvailability({
    feature: Feature.GUEST_CONNECT,
  })

  return (
    <Box>
      <div>
        <FeatureBlockerText
          hidden={!guestConnectAvailability.ready}
          isFeatureAvailable={guestConnectAvailability.available}
        >
          <MText variant="heading2" marginBottom={spacing.S}>
            {t(langKeys.shortcodes)}
          </MText>
        </FeatureBlockerText>

        <MText variant="body" color="secondary">
          {t(langKeys.home_short_codes_description)}{" "}
          <ExternalLink href={HREF_MINUT_HELP_SHORTCODES}>
            {t(langKeys.learn_more)}
          </ExternalLink>
        </MText>
      </div>

      <section>
        <ShortcodeTable
          homeId={homeId}
          rowData={[
            {
              id: "checkin_message",
              message:
                guestCommunication?.checkin_message || DEFAULT_CHECKIN_MESSAGE,
              name: t(langKeys.check_in_instructions),
            },
          ]}
        />
      </section>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  grid-gap: ${spacing.L};
`
