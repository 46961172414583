import { useState } from "react"
import styled from "styled-components"

import { NoiseMonitoringButton } from "src/components/Homes/HomeDetails/NoiseMonitoring/NoiseMonitoringButton"
import {
  CardStatusRow,
  MonitoringCard,
  StatusLeft,
} from "src/components/Homes/HomeDetails/Overview/MonitoringCard"
import { NoiseMonitoringGraph } from "src/components/Homes/HomeDetails/Overview/NoiseMonitoringGraph"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { NoiseMonitoringState } from "src/data/homeActions/types/noiseMonitoringTypes"
import { useFetchResponseServiceProvider } from "src/data/homes/queries/responseServiceQueries"
import {
  TDisturbanceMonitoringActive,
  Violations,
} from "src/data/homes/types/homeTypes"
import { IResponseService } from "src/data/homes/types/responseServiceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePutHomeDisturbance } from "src/data/organizations/queries/homeQueries"
import { TPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import GuardAssistIcon from "src/ui/icons/guard-assist-on-filled.svg"
import ResponseServiceIcon from "src/ui/icons/guard-assist-outlined.svg"
import NoiseMonitoringIcon from "src/ui/icons/noise-monitoring.svg"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function NoiseMonitoringCard({
  homeName,
  homeId,
  monitoringActive,
  monitoringStateUpdatedAt,
  monitoringState,
  noiseDuration = 0,
  timezone,
  violations = [],
  toggleAllowed,
  responseService,
  device,
  noisePreset,
  loading,
  ...props
}: {
  homeName: string
  homeId: string
  monitoringStateUpdatedAt: Date
  monitoringActive: TDisturbanceMonitoringActive
  monitoringState: NoiseMonitoringState
  noiseDuration: number
  timezone: string
  violations: Violations[] | undefined
  toggleAllowed: boolean
  responseService: IResponseService | undefined
  device: TDevice
  noisePreset: TPreset | undefined
  loading?: boolean

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  props?: any
}) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()

  function noiseDurationToSecs() {
    const duration = noiseDuration

    return Math.round(duration / 60)
  }

  function getStatusDescription() {
    if (!monitoringActive) {
      return ""
    }

    switch (monitoringState) {
      case "level_1":
      case "level_2":
      case "level_3":
      case "level_4":
      case "snoozed":
        return t(langKeys.disturbance_warning_state_title, {
          0: noiseDurationToSecs(),
        })

      default:
        return ""
    }
  }
  const statusDescription = getStatusDescription()

  const graph = !loading && monitoringActive && (
    <NoiseMonitoringGraph
      device={device}
      noiseMonitoringState={monitoringState}
      timezone={timezone}
      noisePreset={noisePreset}
    />
  )

  function ActionButton() {
    return (
      <NoiseMonitoringButton
        homeId={homeId}
        violations={violations}
        isActive={monitoringActive}
        toggleAllowed={toggleAllowed}
        monitoringState={monitoringState}
      />
    )
  }

  function LeftCol() {
    const statusContents = monitoringActive ? t(langKeys.on) : t(langKeys.off)

    return (
      <StatusLeft
        icon={<NoiseMonitoringIcon height={32} width={32} />}
        title={t(langKeys.sound_noise_monitoring)}
        statusContents={statusContents}
        isOn={monitoringActive}
        statusInfo={
          !!monitoringActive && !!statusDescription ? (
            <MText variant="body" color="emergency">
              {statusDescription}
            </MText>
          ) : null
        }
        secondaryFeatureStatus={
          responseService?.active && <GuardAssistIcon height={27} width={27} />
        }
      />
    )
  }

  return (
    <div>
      <MonitoringCard
        {...props}
        titleSlots={[<LeftCol key="left" />, <ActionButton key="right" />]}
        cardFooter={{
          showTopBorder: !!responseService?.active,
          component: (
            <ResponseServiceBox
              orgId={org.id}
              responseService={responseService}
              homeName={homeName}
              homeId={homeId}
              monitoringStateUpdatedAt={monitoringStateUpdatedAt}
              monitoringState={monitoringState}
            />
          ),
        }}
      >
        {!!monitoringActive && graph}
      </MonitoringCard>
    </div>
  )
}

function ResponseServiceBox({
  orgId,
  homeId,
  homeName,
  monitoringStateUpdatedAt,
  monitoringState,
  responseService,
}: {
  orgId: string
  responseService: IResponseService | undefined
  homeName: string
  homeId: string
  monitoringStateUpdatedAt: Date
  monitoringState: NoiseMonitoringState
}) {
  const { t, langKeys } = useTranslate()
  const putHomeDisturbance = usePutHomeDisturbance()
  const [manualDispatchPushedAt, setManualDispatchPushedAt] = useState(
    new Date(0) // set initial date to beginning of time
  )
  const [showDispatchDialog, setShowDispatchDialog] = useState(false)
  const fetchResponseServiceProvider = useFetchResponseServiceProvider({
    providerId: responseService?.provider || "",
    options: {
      enabled: !!responseService?.provider,
    },
  })

  const shouldDisableManualDispatchButton =
    (monitoringState === "level_3" && !!responseService?.dispatch_status) ||
    monitoringState === "level_4" ||
    monitoringState === "snoozed" ||
    monitoringState === "idle" ||
    manualDispatchPushedAt >= monitoringStateUpdatedAt

  function handleManualDispatch() {
    setShowDispatchDialog(false)
    setManualDispatchPushedAt(new Date())
    putHomeDisturbance.mutate({
      orgId,
      homeId,
      body: {
        action: "dispatch",
      },
    })
  }

  if (!responseService?.active) {
    return null
  }

  if (
    !responseService?.provider ||
    !fetchResponseServiceProvider.data ||
    fetchResponseServiceProvider.isInitialLoading
  ) {
    return <MCircularProgress />
  }

  return (
    <div>
      <ResponseServiceStatusBox>
        <CardStatusRow
          icon={<ResponseServiceIcon width={24} />}
          subtitle={t(langKeys.guard_assist_enabled)}
          body={t(langKeys.response_service_by_provider, {
            provider: fetchResponseServiceProvider.data.name,
          })}
        />

        <MButton
          size="small"
          onClick={() => {
            setShowDispatchDialog(true)
          }}
          loading={putHomeDisturbance.isLoading}
          disabled={shouldDisableManualDispatchButton}
        >
          {t(langKeys.disturbance_dispatch_manually_action)}
        </MButton>
      </ResponseServiceStatusBox>
      {showDispatchDialog && (
        <ConfirmDialog
          onClose={() => setShowDispatchDialog(false)}
          onConfirm={() => handleManualDispatch()}
          title={t(langKeys.disturbance_dispatch_manually_dialog_title, [
            homeName,
          ])}
          confirmLabel={t(langKeys.disturbance_dispatch_manually_action)}
          open={true}
        >
          {t(langKeys.disturbance_dispatch_manually_dialog_message, [
            fetchResponseServiceProvider.data.name,
          ])}
        </ConfirmDialog>
      )}
    </div>
  )
}

const ResponseServiceStatusBox = styled.div`
  display: flex;
  gap: ${spacing.S};
  justify-content: space-between;
  align-items: center;
`
