import { ReactNode } from "react-markdown"
import styled from "styled-components"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { mColors } from "src/ui/colors"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText, MTextProps } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MonitoringCard({
  titleSlots,
  cardFooter,
  children,
  ...props
}: {
  titleSlots: ((JSX.Element & { key: React.Key | null }) | null | undefined)[]
  cardFooter?: { showTopBorder: boolean; component: React.ReactNode }
  children?: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  props?: any
}) {
  return (
    <MonitoringCardBox {...props}>
      <MonitoringCardHeader>
        {titleSlots.map((component) => component)}
      </MonitoringCardHeader>
      <section>{!!children ? children : null}</section>

      {!!cardFooter && (
        <MonitoringCardFooter $showTopBorder={cardFooter.showTopBorder}>
          {cardFooter.component}
        </MonitoringCardFooter>
      )}
    </MonitoringCardBox>
  )
}

export function StatusLeft({
  icon,
  title,
  isOn,
  statusContents,
  secondaryFeatureStatus,
  statusInfo,
  showUpgradeButton,
}: {
  icon: JSX.Element
  title: React.ReactNode
  statusContents: string
  isOn: boolean
  secondaryFeatureStatus?: React.ReactNode
  statusInfo?: JSX.Element | null
  showUpgradeButton?: boolean
}) {
  return (
    <StatusLeftLayout>
      <div>{icon}</div>

      <div>
        <TitleRow>
          <MText variant="heading3">{title}</MText>

          <FeatureStatus>
            {showUpgradeButton && (
              <UpgradeIconButton context="featureblocker-generic" />
            )}

            <MBadge color={isOn ? "info" : "neutral"}>{statusContents}</MBadge>

            {secondaryFeatureStatus}
          </FeatureStatus>
        </TitleRow>

        {statusInfo}
      </div>
    </StatusLeftLayout>
  )
}

export function CardStatusRow({
  icon,
  subtitle,
  body,
  color = "primary",
}: {
  icon: JSX.Element
  subtitle: ReactNode
  body?: ReactNode
  color?: MTextProps["color"]
}) {
  return (
    <FooterStatusBox>
      <MText color={color}>{icon}</MText>
      <div>
        <MText variant="subtitle" color={color}>
          {subtitle}
        </MText>
        <MText variant="body" color="tertiary">
          {body}
        </MText>
      </div>
    </FooterStatusBox>
  )
}

const MonitoringCardBox = styled.div`
  border: 1px solid ${mColors.divider};
  border-radius: 1rem;
  padding: ${spacing.L};
  overflow-x: auto;
`

const MonitoringCardHeader = styled.div`
  display: flex;
  gap: ${spacing.M} ${spacing.XS2};
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

const MonitoringCardFooter = styled.div<{ $showTopBorder: boolean }>`
  ${({ $showTopBorder }) =>
    $showTopBorder &&
    ` 
border-top: 1px solid ${mColors.divider};
margin-top: ${spacing.L};
padding-top: ${spacing.L};`}
`

const StatusLeftLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.M};
  align-items: center;
`

const TitleRow = styled.div`
  display: flex;
  gap: ${spacing.M};
`

const FeatureStatus = styled.div`
  display: flex;
  gap: ${spacing.XS2};
  align-items: center;
`

const FooterStatusBox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.XS};
`
