import { useMemo } from "react"
import styled from "styled-components"

import { subHours } from "date-fns"

import { useFetchDeviceReadings } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useBackendFlags } from "src/data/flags/useBackendFlags"
import { NoiseMonitoringState } from "src/data/homeActions/types/noiseMonitoringTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { TPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { semanticEmergency, semanticGood } from "src/ui/colors"
import {
  extractActiveNoiseThreshold,
  extractPresetNoiseThreshold,
} from "src/ui/Graphs/configurationUtils"
import { LineChart } from "src/ui/Graphs/LineChart"
import { getResolution } from "src/ui/Graphs/utils"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { GraphBox } from "./GraphBox"

export function NoiseMonitoringGraph({
  title,
  device,
  noiseMonitoringState,
  timezone,
  noisePreset,
}: {
  device: TDevice
  noiseMonitoringState?: NoiseMonitoringState
  title?: string
  timezone: string
  noisePreset?: TPreset
}) {
  const { noise_profiles_released: presets } = useBackendFlags()

  const { orgId } = useOrganization()
  const user = useGetUser()
  const endAt = useMemo(
    () => new Date(device.last_heartbeat_at ?? Date.now()),
    [device.last_heartbeat_at]
  )
  const startAt = subHours(endAt, 1)

  const fetchDeviceReadings = useFetchDeviceReadings({
    orgId,
    type: "sound_level",
    deviceId: device.device_id,
    startAt: startAt.toISOString(),
    endAt: endAt.toISOString(),
    timeResolution: getResolution({ startDate: startAt, endDate: endAt }),
  })

  const expectedValues =
    fetchDeviceReadings.data?.values.map((reading) => ({
      ...reading,
      dateTimeUtc: reading.datetime,
    })) || []

  function getLineColor() {
    return noiseMonitoringState === "idle" ? semanticGood : semanticEmergency
  }

  const noiseThresholdConfig = device.placed_outdoors
    ? noisePreset?.outdoor_noise_threshold
    : noisePreset?.indoor_noise_threshold

  const activeNoiseThreshold = presets
    ? extractPresetNoiseThreshold({
        isNoiseMonitoringEnabled: true,
        noiseThresholdConfig,
      })
    : extractActiveNoiseThreshold(device.configuration, true)

  return (
    <Box>
      {!!title && <MText variant="heading3">{title}</MText>}

      <GraphBox isLoading={fetchDeviceReadings.isLoading}>
        <LineChart
          data={expectedValues}
          thresholds={activeNoiseThreshold}
          interactive={false}
          timezone={timezone}
          clockType={user.clock_type}
          lineColor={getLineColor()}
          lineEndMarker={{
            unit: "dB",
            decimals: 0,
          }}
          height={250}
        />
      </GraphBox>
    </Box>
  )
}

const Box = styled.div`
  margin-top: ${spacing.M};
`
