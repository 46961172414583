import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"

export function AddVirtualDeviceButton({ homeId }: { homeId: string }) {
  const { navigate } = useRouter()

  return (
    <MButton
      variant="subtle"
      onClick={() => navigate(Routes.AddVirtualDevice.location(homeId))}
    >
      Add virtual device
    </MButton>
  )
}
