import { useState } from "react"
import styled from "styled-components"

import { HomeRolePicker } from "src/components/Organizations/Organization/HomeRolePicker"
import {
  useDeleteHomeMember,
  usePatchHomeMember,
} from "src/data/organizations/queries/homeQueries"
import { useFetchMemberSelf } from "src/data/organizations/queries/memberQueries"
import {
  HomeRole,
  IHomeMember,
  IOrganizationMember,
} from "src/data/organizations/types/organizationMemberTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DebugBox } from "src/ui/Debug/DebugBox"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeMemberTable({
  orgId,
  homeId,
  members,
  loading: _loading,
  adminAccess,
}: {
  orgId: string
  homeId: string
  members?: IHomeMember[]
  loading?: boolean
  adminAccess: boolean
}) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const fetchMemberSelf = useFetchMemberSelf({ orgId })
  const patchHomeMember = usePatchHomeMember()
  const deleteHomeMember = useDeleteHomeMember()
  const [deleteMemberData, setDeleteMemberData] = useState<null | {
    memberId: string
    deleteSelf?: boolean
    displayName: string
  }>(null)

  function onDelete(memberId: string, deleteSelf?: boolean) {
    deleteHomeMember.mutate(
      { orgId, homeId, memberId },
      {
        onSuccess: () => {
          setDeleteMemberData(null) // close the dialog
          if (deleteSelf) {
            navigate(Routes.Dashboard.location())
          }
        },
      }
    )
  }

  function onChange(memberId: string, body: Partial<IHomeMember>) {
    patchHomeMember.mutate({ orgId, homeId, memberId, body })
  }

  function rows(selfMember: IOrganizationMember) {
    return members?.map((m) => (
      <MemberRow
        key={m.member_id}
        orgId={orgId}
        member={m}
        selfMember={selfMember}
        onDelete={(memberId, deleteSelf) =>
          setDeleteMemberData({ memberId, deleteSelf, displayName: m.name })
        }
        onChange={onChange}
        loading={patchHomeMember.isLoading || deleteHomeMember.isLoading}
        adminAccess={adminAccess}
      />
    ))
  }

  if (!fetchMemberSelf.data) {
    return null
  }

  return (
    <div>
      <GridTable
        header={[
          <div key={"name"}>{t(langKeys.name)}</div>,
          <div key={"role-title"}>
            {t(langKeys.organizations_organization_role_title)}
          </div>,
          <div key={"misc"}></div>,
        ]}
        rows={rows(fetchMemberSelf.data) ?? []}
        templateColumns="2fr auto auto"
        useFallbackResponsiveMode
      />

      <ConfirmDialog
        title={t(langKeys.organizations_leave_team_confirm_title)}
        description={
          deleteMemberData?.deleteSelf
            ? t(langKeys.organizations_home_member_remove_body)
            : t(langKeys.organizations_home_member_remove_confirm_body, {
                user: deleteMemberData?.displayName ?? "",
              })
        }
        loading={deleteHomeMember.isLoading}
        open={!!deleteMemberData}
        onClose={() => setDeleteMemberData(null)}
        onConfirm={() => {
          if (!deleteMemberData) {
            throw Error(
              "Delete member data is null, which should not happen in this dialog"
            )
          }
          onDelete(deleteMemberData.memberId, deleteMemberData.deleteSelf)
        }}
        confirmButtonProps={{ color: "destructive" }}
      />
      <DebugBox data={members} minimized />
    </div>
  )
}

function MemberRow({
  member,
  selfMember,
  loading,
  onChange,
  onDelete,
  adminAccess,
}: {
  orgId: string
  member: IHomeMember
  selfMember: IOrganizationMember
  loading?: boolean
  onChange: (memberId: string, body: Partial<IHomeMember>) => void
  onDelete: (memberId: string, deleteSelf?: boolean) => void
  adminAccess: boolean
}) {
  const { t, langKeys } = useTranslate()
  const isSelf = selfMember.member_id === member.member_id

  function setRole(role: HomeRole) {
    onChange(member.member_id, { role })
  }

  return (
    <>
      <div>
        <div>{member.name}</div>
        <MText variant="bodyS" color="secondary">
          {member.email}
        </MText>
      </div>
      <div>
        <HomeRolePicker
          inline
          value={member.role}
          disabled={loading || isSelf || !adminAccess}
          onChange={setRole}
        />
      </div>
      <MoreButtonWrapper>
        {(isSelf && (
          <MoreButton
            disabled={loading}
            onDelete={() => onDelete(member.member_id, isSelf)}
            deleteLabel={t(langKeys.home_leave_home)}
            showDeleteIcon={false}
          />
        )) || (
          <MoreButton
            disabled={loading || !adminAccess}
            onDelete={() => onDelete(member.member_id, isSelf)}
          />
        )}
      </MoreButtonWrapper>
    </>
  )
}

const MoreButtonWrapper = styled.div`
  padding-left: ${spacing.XL3};
`
