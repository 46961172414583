import styled from "styled-components"

import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHomeWaterLeakDetection } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import { Container } from "src/ui/Container/Container"
import { ExpandableAttentionCard } from "src/ui/ExpandableSection/ExpandableAttentionCard"
import WaterLeakInfoSvg from "src/ui/icons/info.svg"
import { BodyMixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function WaterLeakDetectedCard({ homeId }: { homeId: string }) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const patchWld = usePatchHomeWaterLeakDetection({ orgId })

  function handleDismiss() {
    patchWld.mutate({ path: { home_id: homeId }, body: { status: "idle" } })
  }

  return (
    <ExpandableAttentionCard
      type="emergency"
      title={t(langKeys.water_leak_detected_card_title)}
      id="partial-outage-card"
      storeState={true}
      initialOpen
    >
      <Container>
        <DescriptionBox>
          <Icon />

          <div>
            {t(langKeys.water_leak_detected_card_description)}{" "}
            <MButton
              size="small"
              onClick={handleDismiss}
              variant="minimal"
              color="destructive"
            >
              {t(langKeys.dismiss)}
            </MButton>
          </div>
        </DescriptionBox>
      </Container>
    </ExpandableAttentionCard>
  )
}

const DescriptionBox = styled.div`
  ${BodyMixin};
  display: grid;
  gap: ${spacing.M};
  place-items: center;

  @container (width > 500px) {
    grid-template-columns: auto 1fr;
  }
`

const Icon = styled(WaterLeakInfoSvg)`
  width: 100px;
  color: ${mColors.systemError};
`
