import styled from "styled-components"

import { OccupancyButton } from "src/components/Homes/HomeDetails/Occupancy/OccupancyButton"
import { OccupancyContent } from "src/components/Homes/HomeDetails/Occupancy/OccupancyContent"
import {
  CardStatusRow,
  MonitoringCard,
  StatusLeft,
} from "src/components/Homes/HomeDetails/Overview/MonitoringCard"
import { THome } from "src/data/homes/types/homeTypes"
import { getOrgAccessRank } from "src/data/organizations/logic/organizationAccess"
import { OrganizationRank } from "src/data/organizations/types/organizationMemberTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import CalibratingIcon from "src/ui/icons/calibrating.svg"
import OccupancyIcon from "src/ui/icons/crowd.svg"
import { MInfo } from "src/ui/Info/MInfo"
import { localizedDate } from "src/ui/LocalizedDate/localized-date"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { extractHHMM } from "src/utils/l10n"

export function OccupancyCard({ home }: { home: THome }) {
  const canToggleOccupancy =
    getOrgAccessRank(home.user_role) >= OrganizationRank.ADMIN
  const { t } = useTranslate()

  const occupancy = home.occupancy
  const {
    status,
    active,
    latest_scanned_devices: detected,
    scanned_devices_threshold: threshold,
    latest_scanned_devices_updated_at: lastUpdate,
  } = occupancy || {}

  const aboveThreshold = status === "crowd_detected"

  function ActionButton() {
    return (
      <OccupancyButton
        occupancy={occupancy}
        violations={home.violations}
        homeId={home.home_id}
        toggleAllowed={canToggleOccupancy}
      />
    )
  }

  function LeftCol() {
    const statusContents = active ? t(langKeys.on) : t(langKeys.off)
    const clockType = useGetUser().clock_type

    const time = lastUpdate
      ? extractHHMM(
          new Date(localizedDate(lastUpdate, clockType, home?.timezone)),
          clockType
        )
      : ""

    return (
      <StatusLeft
        icon={<OccupancyIcon width={32} height={32} />}
        title={t(langKeys.crowd_detect_title)}
        statusContents={statusContents}
        isOn={!!active}
        statusInfo={
          active ? <CrowdRiskDescription time={time} status={status} /> : null
        }
      />
    )
  }

  return (
    <MonitoringCard
      titleSlots={[<LeftCol key="left" />, <ActionButton key="right" />]}
      cardFooter={{
        showTopBorder: status === "waiting_for_data",
        component: <OccupancyCardFooter status={status} />,
      }}
    >
      {active && (
        <OccupancyContent
          status={status}
          threshold={threshold}
          detected={detected}
          aboveThreshold={aboveThreshold}
          lastUpdate={lastUpdate}
          homeId={home.home_id}
          timezone={home.timezone}
        />
      )}
    </MonitoringCard>
  )
}

function CrowdRiskDescription({
  status,
  time,
}: {
  status: NonNullable<THome["occupancy"]>["status"]
  time: string
}) {
  const { t } = useTranslate()

  const showDescription = status === "crowd_detected" || status === "processing"

  if (!showDescription) {
    return null
  }

  return (
    <CrowdRiskBox>
      {status === "crowd_detected" && (
        <MText color="emergency">
          {t(langKeys.crowd_detect_active_emergency_title, { 0: time })}
        </MText>
      )}

      {status === "processing" && (
        <MText color="good">
          {t(langKeys.crowd_detect_active_normal_title, { 0: time })}
        </MText>
      )}

      <MInfo content={t(langKeys.crowd_detect_crowd_risk_description)} />
    </CrowdRiskBox>
  )
}

function OccupancyCardFooter({
  status,
}: {
  status: NonNullable<THome["occupancy"]>["status"]
}) {
  const { t } = useTranslate()

  if (status !== "waiting_for_data") {
    return null
  }

  return (
    <CardStatusRow
      icon={<CalibratingIcon width={24} />}
      subtitle={t(langKeys.crowd_detect_analyzing_title)}
      body={t(langKeys.crowd_detect_analyzing_short_description)}
    />
  )
}

const CrowdRiskBox = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${spacing.M};
`
