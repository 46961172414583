import { AppStoreLogos } from "src/components/EmptyState/AppStoreLogos"
import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"

import HomeDevicesEmptyImage from "./images/home-devices-empty-state.svg"

export function NoHomeDevices() {
  const { t, langKeys } = useTranslate()

  return (
    <EmptyStateWithImage
      Image={HomeDevicesEmptyImage}
      title={t(langKeys.devices_list_empty_title)}
      description={t(langKeys.devices_list_empty_description)}
      content={<AppStoreLogos />}
      imageSpacing={spacing.S}
    />
  )
}
