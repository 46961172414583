import styled from "styled-components"

import { HREF_MINUT_HELP_SENSORS_OFFLINE } from "src/constants/hrefs"
import { TDevice } from "src/data/devices/types/deviceTypes"
import {
  IDeviceOutageState,
  TPartialOutageState,
} from "src/data/homes/logic/partialOutage"
import { useTranslate } from "src/i18n/useTranslate"
import { ExpandableAttentionCard } from "src/ui/ExpandableSection/ExpandableAttentionCard"
import AlertThreeBuildings from "src/ui/icons/illustrations/three-buildings-color-error.svg"
import LowBatteryInfoIcon from "src/ui/icons/low-battery-info.svg"
import OfflineInfoIcon from "src/ui/icons/offline-info-icon.svg"
import { LearnMore } from "src/ui/Link/LearnMore"
import { BodyMixin, MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

// This type ensures that this card cannot be rendered when the partial outage state is 'ok'
type TCardPartialOutageState = Exclude<
  TPartialOutageState,
  Extract<TPartialOutageState, "ok">
>

export function PartialOutageCard({
  deviceOutageState,
  partialOutageState,
}: {
  deviceOutageState: IDeviceOutageState<TDevice>
  partialOutageState: TCardPartialOutageState
}) {
  const { t, langKeys } = useTranslate()

  const {
    lowBatteryDevices,
    offlineDevices,
    nbrOfflineDevices,
    nbrLowBattery,
  } = deviceOutageState

  function getTitleText() {
    switch (partialOutageState) {
      case "offline_sensors":
        return t(langKeys.partial_outage_title_offline_devices, {
          0: nbrOfflineDevices,
          count: nbrOfflineDevices,
        })

      case "low_battery":
        return t(langKeys.partial_outage_title_low_battery_devices, {
          0: nbrLowBattery,
          count: nbrLowBattery,
        })
    }
  }

  return (
    <ExpandableAttentionCard
      type="warning"
      title={getTitleText()}
      id="partial-outage-card"
      storeState={true}
    >
      <DescriptionBox>
        <StyledBuildings />
        <AlertDescription>
          <DeviceOfflineInfo
            nbrOfflineDevices={nbrOfflineDevices}
            offlineDevices={offlineDevices}
          />

          <DeviceLowBatteryInfo
            nbrLowBattery={nbrLowBattery}
            lowBatteryDevices={lowBatteryDevices}
          />
        </AlertDescription>
      </DescriptionBox>
    </ExpandableAttentionCard>
  )
}

function DeviceLowBatteryInfo({
  nbrLowBattery,
  lowBatteryDevices,
}: {
  nbrLowBattery: number
  lowBatteryDevices: TDevice[]
}) {
  const { t, langKeys } = useTranslate()

  const names = lowBatteryDevices.map((d) => d.description).join(", ")
  const lowBatteryPrefix =
    nbrLowBattery === 1
      ? t(langKeys.low_battery_in_prefix)
      : `${t(langKeys.low_battery_in_prefix)}:`

  const helpText = t(langKeys.partial_outage_low_battery_devices_description, {
    count: nbrLowBattery,
  })

  if (!nbrLowBattery) {
    return null
  }

  return (
    <OutageInfo>
      <div>
        <span>
          <LowBatteryInfoIcon width={24} /> {lowBatteryPrefix}{" "}
        </span>
        <MText variant="subtitle" as="span">
          {names}
        </MText>
      </div>

      <div>
        {helpText}{" "}
        <LearnMore href={HREF_MINUT_HELP_SENSORS_OFFLINE}>
          {t(langKeys.read_more)}
        </LearnMore>
      </div>
    </OutageInfo>
  )
}

function DeviceOfflineInfo({
  nbrOfflineDevices,
  offlineDevices,
}: {
  nbrOfflineDevices: number
  offlineDevices: TDevice[]
}) {
  const { t, langKeys } = useTranslate()

  const names = offlineDevices.map((d) => d.description).join(", ")
  const helpText = t(langKeys.partial_outage_devices_offline_description, {
    count: nbrOfflineDevices,
  })
  const offlineSensors =
    nbrOfflineDevices === 1 ? (
      <span>
        <MText variant="subtitle" as="span">
          {names}{" "}
        </MText>
        <span>{t(langKeys.device_offline_suffix)}</span>
      </span>
    ) : (
      <span>
        <span>{t(langKeys.offline_sensors)} </span>
        <MText variant="subtitle" as="span">
          {names}
        </MText>
      </span>
    )

  if (!nbrOfflineDevices) {
    return null
  }

  return (
    <OutageInfo>
      <div>
        <OfflineInfoIcon width={24} /> {offlineSensors}
      </div>

      <div>
        {helpText}{" "}
        <LearnMore href={HREF_MINUT_HELP_SENSORS_OFFLINE}>
          {t(langKeys.read_more)}
        </LearnMore>
      </div>
    </OutageInfo>
  )
}

const OutageInfo = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const DescriptionBox = styled.div`
  ${BodyMixin};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  gap: ${spacing.M};
`

const AlertDescription = styled.div`
  margin: ${spacing.M} 0;
  display: grid;
  align-items: center;
  gap: ${spacing.XL};
  flex: 1 0 300px;
`

const StyledBuildings = styled(AlertThreeBuildings)`
  max-width: 100px;
`
